/* GLYPHICONS FILETYPES 1.8 */

@font-face {
  font-family: "Glyphicons Filetypes";
  src: url(/assets/fonts/glyphicons/glyphicons-filetypes-regular.woff) format("woff"), url(/assets/fonts/glyphicons/glyphicons-filetypes-regular.ttf) format("truetype"), url(/assets/fonts/glyphicons/glyphicons-filetypes-regular.svg#glyphicons_filetypesregular) format("svg"); }

$icon-prefix: glyphicon;

[class*="#{$icon-prefix}-"] {
  display: inline-block;
  vertical-align: middle;
  font: {
    family: "Glyphicons Filetypes";
    style: normal;
    weight: normal;
    size: 1em; } }

.#{$icon-prefix}-spin {
  animation: #{$icon-prefix}-spin 2s infinite linear; }

@-moz-keyframes #{$icon-prefix}-spin {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(359deg); } }
@-webkit-keyframes #{$icon-prefix}-spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg); } }
@-o-keyframes #{$icon-prefix}-spin {
  0% {
    -o-transform: rotate(0deg); }
  100% {
    -o-transform: rotate(359deg); } }
@-ms-keyframes #{$icon-prefix}-spin {
  0% {
    -ms-transform: rotate(0deg); }
  100% {
    -ms-transform: rotate(359deg); } }
@keyframes #{$icon-prefix}-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

[class*="#{$icon-prefix}-"]:before {
  content: "\E120"; }

.#{$icon-prefix}-txt:before {
  content: "\E001"; }
.#{$icon-prefix}-doc:before {
  content: "\E002"; }
.#{$icon-prefix}-rtf:before {
  content: "\E003"; }
.#{$icon-prefix}-log:before {
  content: "\E004"; }
.#{$icon-prefix}-tex:before {
  content: "\E005"; }
.#{$icon-prefix}-msg:before {
  content: "\E006"; }
.#{$icon-prefix}-text:before {
  content: "\E007"; }
.#{$icon-prefix}-wpd:before {
  content: "\E008"; }
.#{$icon-prefix}-wps:before {
  content: "\E009"; }
.#{$icon-prefix}-docx:before {
  content: "\E010"; }
.#{$icon-prefix}-page:before {
  content: "\E011"; }
.#{$icon-prefix}-csv:before {
  content: "\E012"; }
.#{$icon-prefix}-dat:before {
  content: "\E013"; }
.#{$icon-prefix}-tar:before {
  content: "\E014"; }
.#{$icon-prefix}-xml:before {
  content: "\E015"; }
.#{$icon-prefix}-vcf:before {
  content: "\E016"; }
.#{$icon-prefix}-pps:before {
  content: "\E017"; }
.#{$icon-prefix}-key:before {
  content: "\E018"; }
.#{$icon-prefix}-ppt:before {
  content: "\E019"; }
.#{$icon-prefix}-pptx:before {
  content: "\E020"; }
.#{$icon-prefix}-sdf:before {
  content: "\E021"; }
.#{$icon-prefix}-gbr:before {
  content: "\E022"; }
.#{$icon-prefix}-ged:before {
  content: "\E023"; }
.#{$icon-prefix}-mp3:before {
  content: "\E024"; }
.#{$icon-prefix}-m4a:before {
  content: "\E025"; }
.#{$icon-prefix}-waw:before {
  content: "\E026"; }
.#{$icon-prefix}-wma:before {
  content: "\E027"; }
.#{$icon-prefix}-mpa:before {
  content: "\E028"; }
.#{$icon-prefix}-iff:before {
  content: "\E029"; }
.#{$icon-prefix}-aif:before {
  content: "\E030"; }
.#{$icon-prefix}-ra:before {
  content: "\E031"; }
.#{$icon-prefix}-mid:before {
  content: "\E032"; }
.#{$icon-prefix}-m3v:before {
  content: "\E033"; }
.#{$icon-prefix}-e_3gp:before {
  content: "\E034"; }
.#{$icon-prefix}-shf:before {
  content: "\E035"; }
.#{$icon-prefix}-avi:before {
  content: "\E036"; }
.#{$icon-prefix}-asx:before {
  content: "\E037"; }
.#{$icon-prefix}-mp4:before {
  content: "\E038"; }
.#{$icon-prefix}-e_3g2:before {
  content: "\E039"; }
.#{$icon-prefix}-mpg:before {
  content: "\E040"; }
.#{$icon-prefix}-asf:before {
  content: "\E041"; }
.#{$icon-prefix}-vob:before {
  content: "\E042"; }
.#{$icon-prefix}-wmv:before {
  content: "\E043"; }
.#{$icon-prefix}-mov:before {
  content: "\E044"; }
.#{$icon-prefix}-srt:before {
  content: "\E045"; }
.#{$icon-prefix}-m4v:before {
  content: "\E046"; }
.#{$icon-prefix}-flv:before {
  content: "\E047"; }
.#{$icon-prefix}-rm:before {
  content: "\E048"; }
.#{$icon-prefix}-png:before {
  content: "\E049"; }
.#{$icon-prefix}-psd:before {
  content: "\E050"; }
.#{$icon-prefix}-psp:before {
  content: "\E051"; }
.#{$icon-prefix}-jpg:before, .#{$icon-prefix}-jpeg:before {
  content: "\E052"; }
.#{$icon-prefix}-tif:before {
  content: "\E053"; }
.#{$icon-prefix}-tiff:before {
  content: "\E054"; }
.#{$icon-prefix}-gif:before {
  content: "\E055"; }
.#{$icon-prefix}-bmp:before {
  content: "\E056"; }
.#{$icon-prefix}-tga:before {
  content: "\E057"; }
.#{$icon-prefix}-thm:before {
  content: "\E058"; }
.#{$icon-prefix}-yuv:before {
  content: "\E059"; }
.#{$icon-prefix}-dds:before {
  content: "\E060"; }
.#{$icon-prefix}-ai:before {
  content: "\E061"; }
.#{$icon-prefix}-eps:before {
  content: "\E062"; }
.#{$icon-prefix}-ps:before {
  content: "\E063"; }
.#{$icon-prefix}-svg:before {
  content: "\E064"; }
.#{$icon-prefix}-pdf:before {
  content: "\E065"; }
.#{$icon-prefix}-pct:before {
  content: "\E066"; }
.#{$icon-prefix}-indd:before {
  content: "\E067"; }
.#{$icon-prefix}-xlr:before {
  content: "\E068"; }
.#{$icon-prefix}-xls:before {
  content: "\E069"; }
.#{$icon-prefix}-xlsx:before {
  content: "\E070"; }
.#{$icon-prefix}-db:before {
  content: "\E071"; }
.#{$icon-prefix}-dbf:before {
  content: "\E072"; }
.#{$icon-prefix}-mdb:before {
  content: "\E073"; }
.#{$icon-prefix}-pdb:before {
  content: "\E074"; }
.#{$icon-prefix}-sql:before {
  content: "\E075"; }
.#{$icon-prefix}-aacd:before {
  content: "\E076"; }
.#{$icon-prefix}-app:before {
  content: "\E077"; }
.#{$icon-prefix}-exe:before {
  content: "\E078"; }
.#{$icon-prefix}-com:before {
  content: "\E079"; }
.#{$icon-prefix}-bat:before {
  content: "\E080"; }
.#{$icon-prefix}-apk:before {
  content: "\E081"; }
.#{$icon-prefix}-jar:before {
  content: "\E082"; }
.#{$icon-prefix}-hsf:before {
  content: "\E083"; }
.#{$icon-prefix}-pif:before {
  content: "\E084"; }
.#{$icon-prefix}-vb:before {
  content: "\E085"; }
.#{$icon-prefix}-cgi:before {
  content: "\E086"; }
.#{$icon-prefix}-css:before {
  content: "\E087"; }
.#{$icon-prefix}-js:before {
  content: "\E088"; }
.#{$icon-prefix}-php:before {
  content: "\E089"; }
.#{$icon-prefix}-xhtml:before {
  content: "\E090"; }
.#{$icon-prefix}-htm:before {
  content: "\E091"; }
.#{$icon-prefix}-html:before {
  content: "\E092"; }
.#{$icon-prefix}-asp:before {
  content: "\E093"; }
.#{$icon-prefix}-cer:before {
  content: "\E094"; }
.#{$icon-prefix}-jsp:before {
  content: "\E095"; }
.#{$icon-prefix}-cfm:before {
  content: "\E096"; }
.#{$icon-prefix}-aspx:before {
  content: "\E097"; }
.#{$icon-prefix}-rss:before {
  content: "\E098"; }
.#{$icon-prefix}-csr:before {
  content: "\E099"; }
.#{$icon-prefix}-less:before {
  content: "\003C"; }
.#{$icon-prefix}-otf:before {
  content: "\E101"; }
.#{$icon-prefix}-ttf:before {
  content: "\E102"; }
.#{$icon-prefix}-font:before {
  content: "\E103"; }
.#{$icon-prefix}-fnt:before {
  content: "\E104"; }
.#{$icon-prefix}-eot:before {
  content: "\E105"; }
.#{$icon-prefix}-woff:before {
  content: "\E106"; }
.#{$icon-prefix}-zip:before {
  content: "\E107"; }
.#{$icon-prefix}-zipx:before {
  content: "\E108"; }
.#{$icon-prefix}-rar:before {
  content: "\E109"; }
.#{$icon-prefix}-targ:before {
  content: "\E110"; }
.#{$icon-prefix}-sitx:before {
  content: "\E111"; }
.#{$icon-prefix}-deb:before {
  content: "\E112"; }
.#{$icon-prefix}-e_7z:before {
  content: "\E113"; }
.#{$icon-prefix}-pkg:before {
  content: "\E114"; }
.#{$icon-prefix}-rpm:before {
  content: "\E115"; }
.#{$icon-prefix}-cbr:before {
  content: "\E116"; }
.#{$icon-prefix}-gz:before {
  content: "\E117"; }
.#{$icon-prefix}-dmg:before {
  content: "\E118"; }
.#{$icon-prefix}-cue:before {
  content: "\E119"; }
.#{$icon-prefix}-bin:before {
  content: "\E120"; }
.#{$icon-prefix}-iso:before {
  content: "\E121"; }
.#{$icon-prefix}-hdf:before {
  content: "\E122"; }
.#{$icon-prefix}-vcd:before {
  content: "\E123"; }
.#{$icon-prefix}-bak:before {
  content: "\E124"; }
.#{$icon-prefix}-tmp:before {
  content: "\E125"; }
.#{$icon-prefix}-ics:before {
  content: "\E126"; }
.#{$icon-prefix}-msi:before {
  content: "\E127"; }
.#{$icon-prefix}-cfg:before {
  content: "\E128"; }
.#{$icon-prefix}-ini:before {
  content: "\E129"; }
.#{$icon-prefix}-prf:before {
  content: "\E130"; }
