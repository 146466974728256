.preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 324px;
  height: 56px;
  background: transparent url("../assets/images/sharedrop.svg") no-repeat center;
  background-size: 324px 56px;
  transition: opacity .2s;
  > span {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: -18px;
    font-size: 1.4rem; } }

.ember-application {
  .preloader {
    opacity: 0; } }

.error {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 50rem;
  height: 15rem;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1.5em; }

.circles {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 1140px;
  margin-left: -570px;
  height: 700px;
  z-index: -1;
  transform-origin: 570px 570px;
  animation: grow 1.5s ease-out;
  .circle {
    stroke-width: .4;
    fill: rgba(0,0,0,0); } }

@keyframes grow {
    50% {
      transform: scale(1.5, 1.5);
      opacity: .2; }

    51% {
        transform: scale(0.5, 0.5);
        opacity: 0; } }
