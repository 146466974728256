@media (max-height: 520px) {
  .modal-body {
    height: auto;
    bottom: auto;
    margin: 15px auto; } }

@media (max-width: 768px) {
  .preloader {
    width: 240px;
    height: 41px;
    background-size: 240px 41px; }

  .modal-body {
    width: 90%;
    height: auto;
    bottom: auto;
    margin: 15px auto;
    .note {
      display: block; } }

  .l-content {
    padding: 80px 0 115px;
    min-height inherit {}
    height: auto; }

  .user {
    .peer {
      position: relative;
      left: auto !important;
      bottom: auto !important;
      height: 106px;
      width: 100%;
      padding: 15px;
      border-bottom: 1px solid #eee;
      margin: 0 !important;
      text-align: left;
      .avatar {
        z-index: 2; }
      .user-info {
        z-index: 1;
        position: absolute;
        top: 30px;
        left: 0;
        padding-left: 170px;
        width: 100%;
        .user-email {
          font-size: 1.4rem; }
        .user-label {
          font-size: 1.8rem; }
        .user-ip {
          font-size: 1.2rem;
          color: #808080; }
        .user-connection-status {
          top: 0;
          left: -9.2rem;
          margin-top: 0;
          transform: scale(1.75, 1.75); } } }
    &.you {
      .peer {
        border-bottom: none; } } }

  .l-header {
    .navbar {
      z-index: 200;
      background: rgba(white,.7);
      .email {
        display: none; }
      .nav {
        > li {
          margin-left: 10px; } } } }

  .l-footer {
    height: 100px;
    padding: 15px;
    .cowbell-labs {
      > span {
        display: none; } }
    .github {
      left: 50%;
      margin-left: -10px; }
    .webrtc, .facebook, .twitter {
      display: none; } }

  .circles {
    display: none; }

  .error {
    width: auto;
    font-size: 1.4rem;
    padding: 0 15px; } }
