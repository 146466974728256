*, *::before, *::after {
  box-sizing: border-box; }

html {
  height: 100%;
  font-family: $font-family;
  font-size: 10px; }

body {
  height: 100%; }

a {
  text-decoration: none; }

b, strong {
  font-weight: bold; }

input, select {
  font-family: inherit;
  padding: 1rem;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: .3rem;
  font-size: 1.4rem; }

.invisible {
  height: 0;
  width: 0;
  opacity: 0; }
