@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

@mixin button_reset {
  margin: 0;
  padding: 0;
  display: inline-block;
  border: none;
  background: none;
  outline: none;
  width: auto;
  cursor: pointer;
  font-family: inherit; }

@mixin shape($size, $shape) {
  display: inline-block;
  width: $size;
  height: $size;
  line-height: $size;
  text-align: center;
  vertical-align: middle;
  @if $shape == circle {
    border-radius: 50%; } }
